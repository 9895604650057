import React from "react";
import { Grid, Box, Divider } from "@material-ui/core";

import PageHeader from "../../Components/Text/PageHeader";

import { FormType, TabModel } from "./TabModel";
import TabBar from "./TabBar";

interface Props { }
interface State {
    isLoading: boolean;
    showForm: FormType;
    currentTab: TabModel;
}
class ActionItem extends React.Component<Props, State> {
    readonly Tabs: TabModel[] = [
        {
            name: 'Dashboard',
            type: 'Dashboard',
            index: 0,
        },
        {
            name: 'Rate Update Exception',
            type: 'RateUpdateException',
            index: 1,
        },
        {
            name: 'Exception Request',
            type: 'ExceptionRequest',
            index: 2,
        },
        {
            name: 'Administration',
            type: 'Administration',
            index: 3,
        },
    ];

    constructor(props: Props | Readonly<Props>) {
        super(props);

        this.state = {
            isLoading: true,
            showForm: 'None',
            currentTab: this.Tabs[0],
        };
    }

    render() {
        const {
            currentTab,
        } = this.state;

        return (
            <Box m={2}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                >
                    <Grid item md={4}>
                        <PageHeader label="Exception Management" />
                    </Grid>
                </Grid>

                <Divider orientation="horizontal" style={{ marginTop: 10, marginBottom: 10 }} />

                <TabBar
                    currentTab={currentTab} tabs={this.Tabs} onTabChange={this.handleTabChange} />
            </Box>
        );
    }

    handleTabChange = (selectedTab: TabModel) => {
        this.setState({ currentTab: selectedTab, showForm: 'None' });
    }

    handleCancelClick = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
        this.setState({ showForm: 'None' });
    }
}

export default ActionItem;