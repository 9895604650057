import React from "react";
import { Box, Grid, Typography, Divider } from "@material-ui/core";
import RedButton from "../../Components/Button/RedButton";
import IconButton from "../../Components/Button/IconButton";
import TextAreaInputField from "../../Components/TextInput/TextAreaInputField";
import {
  ExceptionManagementDTO,
  EmailDTO,
  CommitteeMemberManagementDTO,
  RateExceptionAuditDTO,
  RateExceptionCommentsDTO,
} from "../../Models";
import moment from "moment";
import {
  FMVRateExceptionService,
  CommitteeMemberManagementService,
} from "../../Services";
import ExceptionCommentsLog from "./ExceptionCommentsLog";

interface LabelProps {
  text: string;
}
const Label = (props: LabelProps) => {
  return (
    <Typography
      style={{
        fontWeight: 520,
        color: "#000000d6",
        marginRight: 30,
      }}
    >
      {props.text}
    </Typography>
  );
};

interface Props {
  rateExceptionData: ExceptionManagementDTO;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onTextAreaInputChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onFocus: () => void;
  onBlur: () => void;
  focused: boolean;
}
interface State {
  openTextBox: boolean;
  reviewed: boolean;
  viewAdminDecision: boolean;
  adminDecision: boolean;
  adminApprovedDecision: boolean;
  adminDeniedDecision: boolean;
  adminquorumDecision: boolean;
  adminCancelledDecision: boolean;
  committeeMemberData: CommitteeMemberManagementDTO[];
  toMailData: string;
  ccMailData: string;
  exceptionAudit: RateExceptionAuditDTO;
  exceptionComments: RateExceptionCommentsDTO;
  comments: RateExceptionCommentsDTO[];
}
class FMVAdminDecision extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      openTextBox: false,
      reviewed: false,
      viewAdminDecision: false,
      adminDecision: false,
      adminApprovedDecision: false,
      adminDeniedDecision: false,
      adminquorumDecision: false,
      adminCancelledDecision: false,
      committeeMemberData: [],
      toMailData: "",
      ccMailData: "",
      exceptionAudit: {} as RateExceptionAuditDTO,
      exceptionComments: {} as RateExceptionCommentsDTO,
      comments: [],
    };
  }
  async componentDidMount() {
    const userRole = localStorage.getItem("UserRole");
    if (userRole === "LILLY_ADMIN") {
      this.setState({ viewAdminDecision: true });
    }
    const response = await CommitteeMemberManagementService.getAll();
    this.setState({ committeeMemberData: response });
    const toMemberData = response.filter(
      (p) => p.iscommitteemember === true
    );
    const ccMemberData = response.filter(
      (p) => p.iscommitteemember === false
    );
    const toMailData = toMemberData
      .map((p) => p.email)
      .join(",")
      .toString();
    const ccMailData = ccMemberData
      .map((p) => p.email)
      .join(",")
      .toString();
    this.setState({ toMailData, ccMailData });
    const { rateExceptionData } = this.props;
    if (rateExceptionData.fmvadminteamcomment) {
      this.loadData();
    }
  }

  render(): React.ReactNode {
    const {
      openTextBox,
      reviewed,
      viewAdminDecision,
      adminApprovedDecision,
      adminDeniedDecision,
      adminquorumDecision,
      adminCancelledDecision,
      comments,
    } = this.state;
    const {
      rateExceptionData,
      onTextAreaInputChange,
      onFocus,
      onBlur,
      focused,
    } = this.props;
    return (
      <Box style={{ marginBottom: 50 }}>
        <Grid container direction="row" justify="space-between">
          <Label text="FMV Adminstration and Decision" />
          {viewAdminDecision ? (
            <IconButton iconType="Minimize" onClick={this.handleAdminClick} />
          ) : (
            <IconButton iconType="Add" onClick={this.handleAdminClick} />
          )}
          {/* <IconButton iconType="Add" onClick={this.handleAdminClick} /> */}
        </Grid>
        <Divider
          orientation="horizontal"
          style={{ marginTop: 10, marginBottom: 30 }}
        />

        {viewAdminDecision ? (
          <Grid container>
            <Grid item xs={12}>
              <Label text=" Additional comments about request to include on summary:" />
            </Grid>
            <Grid item xs={12}>
              <TextAreaInputField
                id="fmvadminteamcomment"
                focused={focused}
                width="97.5%"
                value={rateExceptionData.fmvadminteamcomment}
                onChange={onTextAreaInputChange}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            </Grid>
            <Grid item style={{ marginTop: 3 }}>
              <RedButton
                id="save"
                label="Save"
                onClick={this.handleSaveComment}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 15 }}>
              {comments.length > 0 ? (
                <ExceptionCommentsLog comments={comments} />
              ) : (
                <></>
              )}
            </Grid>

            <Grid style={{ marginBottom: 10, marginTop: 20 }} container>
              <Grid item xs={12} style={{ marginBottom: 10 }}>
                <Label text="FMV Team Actions" />
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={2}>
                  <RedButton
                    disabled={
                      rateExceptionData.fmvadminteamstatus ===
                      "Reviewed By FMV Admin"
                        ? true
                        : reviewed
                    }
                    id="view"
                    label="Reviewed and OK to Route"
                    onClick={this.handleReview}
                  />
                  {reviewed ? (
                    <Typography>
                      Reviewed :
                      {`${moment(
                        rateExceptionData.fmvadminteamactiondate
                      ).format("DD-MM-YYYY")}`}
                    </Typography>
                  ) : (
                    <></>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <RedButton
                    id="view"
                    label="Request More Information"
                    onClick={this.handleRequestInfo}
                  />
                </Grid>

                {openTextBox ||
                rateExceptionData.fmvadminteamstatus ===
                  "FMV Admin Requested More Information" ? (
                  <>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <TextAreaInputField
                        id="fmvadminteamneedmoreinfo"
                        focused={focused}
                        width="97.5%"
                        value={rateExceptionData.fmvadminteamneedmoreinfo}
                        onChange={onTextAreaInputChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <RedButton
                        disabled={
                          rateExceptionData.fmvadminteamstatus ===
                          "FMV Admin Requested More Information"
                            ? true
                            : false
                        }
                        id="view"
                        label="Submit"
                        onClick={this.handleSubmit}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 20, marginBottom: 20 }}>
              <Label text="FMV Exception Committee Decision and Communication" />
            </Grid>
            <Grid item container xs={12} style={{ marginBottom: 25 }}>
              <Grid item xs={2}>
                <RedButton
                  disabled={
                    rateExceptionData.exceptiondecision ===
                    "Exception Approved By Committee Member"
                      ? true
                      : adminApprovedDecision
                  }
                  id="view"
                  label="Exception Approved"
                  onClick={this.handleExceptionApproved}
                />
              </Grid>
              <Grid item xs={3}>
                <RedButton
                  disabled={
                    rateExceptionData.exceptiondecision ===
                    "Exception Approved-Quorum Decision"
                      ? true
                      : adminquorumDecision
                  }
                  id="view"
                  label="Exception Approved-Quorum Decision"
                  onClick={this.handleQuorumDecision}
                />
              </Grid>
              <Grid item xs={2}>
                <RedButton
                  disabled={
                    rateExceptionData.exceptiondecision ===
                    "Exception Denied By Committee Member"
                      ? true
                      : adminDeniedDecision
                  }
                  id="view"
                  label="Exception Denied"
                  onClick={this.handleExceptionDenied}
                />
              </Grid>
              <Grid item xs={2}>
                <RedButton
                  disabled={
                    rateExceptionData.exceptiondecision ===
                    "Exception Cancelled By Committee Member"
                      ? true
                      : adminCancelledDecision
                  }
                  id="view"
                  label="Exception Cancelled"
                  onClick={this.handleExceptionCancelled}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    );
  }
  handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const { rateExceptionData } = this.props;
    rateExceptionData.fmvadminteamstatus =
      "FMV Admin Requested More Information";
    if (rateExceptionData) {
      const exceptionAudit: RateExceptionAuditDTO = {
        requestid: rateExceptionData.requestid,
        facultyid: rateExceptionData.facultyid,
        actiontakenby: "FMV Admin Team",
        actiontype: rateExceptionData.fmvadminteamstatus,
        actiondate: new Date(),
        actiondetail: "FMV Admin Team Requested More Information",
        comment: rateExceptionData.fmvadminteamneedmoreinfo,
      };
      this.setState({ exceptionAudit });

      await FMVRateExceptionService.createAudit(exceptionAudit);
      //console.log("response", response);
    }
    const emailObj: EmailDTO = {
      includeAdminInCC: true,
      to: rateExceptionData.requesteremail,
      cc: "",
      subject: `FMV Exception Request for ${rateExceptionData.firstname} ${rateExceptionData.lastname}`,
      body: `<p>Hello,</p><p>The FMV Team has requested more information for about your exception request.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvform/${rateExceptionData.requestid}> here</a> where you can respond with the additional requested information in the comment box.</p><p> Kind regards,</p><p> Lilly FMV</p>`,
    };
    await FMVRateExceptionService.sendEmail(emailObj);
    await FMVRateExceptionService.update(
      this.props.rateExceptionData,
      this.props.rateExceptionData.requestid
    );
    window.location.reload();
  };
  handleSaveComment = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const { rateExceptionData } = this.props;

    await FMVRateExceptionService.update(
      rateExceptionData,
      this.props.rateExceptionData.requestid
    );
    if (rateExceptionData) {
      const exceptionComments: RateExceptionCommentsDTO = {
        requestid: rateExceptionData.requestid,
        facultyid: rateExceptionData.facultyid,
        actiontakenby: "FMV Admin Team",
        actiontype:  "Additional Comment By FMV Admin Team",
        actiondate: new Date(),
        actiondetail: "FMV Admin Team Comment",
        comment: rateExceptionData.fmvadminteamcomment,
      };
      this.setState({ exceptionComments });

      const response = await FMVRateExceptionService.createCommentsLog(
        exceptionComments
      );
      if (response) {
        this.loadData();
      }
      //console.log("response", response);
    }
    //window.location.reload();
  };
  loadData = async () => {
    const { rateExceptionData } = this.props;

    const comments = await FMVRateExceptionService.getAllCommentsLog(
      rateExceptionData.requestid
    );

    this.setState({ comments });
  };
  handleRequestInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ openTextBox: !this.state.openTextBox });
  };
  handleReview = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { rateExceptionData } = this.props;
    rateExceptionData.fmvadminteamactiondate = new Date();
    rateExceptionData.fmvadminteamstatus = "Reviewed By FMV Admin";
    if (rateExceptionData) {
      const exceptionAudit: RateExceptionAuditDTO = {
        requestid: rateExceptionData.requestid,
        facultyid: rateExceptionData.facultyid,
        actiontakenby: "FMV Admin Team",
        actiontype: rateExceptionData.fmvadminteamstatus,
        actiondate: new Date(),
        actiondetail: "Reviewed By FMV Admin Team",
        comment: rateExceptionData.fmvadminteamcomment,
      };
      this.setState({ exceptionAudit });

      await FMVRateExceptionService.createAudit(exceptionAudit);
      //console.log("response", response);
    }
    await FMVRateExceptionService.update(
      rateExceptionData,
      rateExceptionData.requestid
    );
    this.setState({ reviewed: true });
    if (rateExceptionData.vpapproverstatus === "Approved By VP") {
      const emailObj: EmailDTO = {
        includeAdminInCC: true,
        to: this.state.toMailData,
        cc: `${this.state.ccMailData},${rateExceptionData.requesteremail}`,
        subject: `FMV Exception Request for ${rateExceptionData.firstname} ${rateExceptionData.lastname}`,
        body: `<p>Hello,</p><p>An FMV exception request has been received by Global Transparency. This has been approved by the Senior VP or above in business unit/therapeutic area/budget holder.</p><p>Rate Exception Details is as follows:</p>
      <p>Function requesting exception: ${
        rateExceptionData.functionrequestingexception
      }</p>
      <p>Business unit requesting exception: ${
        rateExceptionData.businessunitrequestingexception
      }</p><p>Is there a marketed brand(s)/product(s) associated with the activity the exception is being sought for if applicable? ${
          rateExceptionData.associatedwithactivity
        }</p><p>VP Approver for this request: ${
          rateExceptionData.vpapprovername
        }</p>
      <p>HCP Name : ${rateExceptionData.firstname} ${
          rateExceptionData.lastname
        }</p><p>HCP Type: ${
          rateExceptionData.hcptype
        }</p><p>Lilly current rate: $${
          rateExceptionData.lillycurrentrate
        }</p><p>Lilly Current Group Tier: ${
          rateExceptionData.lillycurrentgrouptier
        }</p>
      <p>BI current rate: $${
        rateExceptionData.bicurrentrate
      }</p><p>BI Current Group Tier: ${
          rateExceptionData.bicurrentgrouptier
        }</p><p>Point to next tier: Lilly (${
          rateExceptionData.lillypointtonexttier
            ? rateExceptionData.lillypointtonexttier
            : "NA"
        }) BI (${
          rateExceptionData.bipointtonexttier
            ? rateExceptionData.bipointtonexttier
            : "NA"
        })</p><p>Requested FMV rate:  ${
          rateExceptionData.rateexceptionforlilly
            ? `Lilly $${rateExceptionData.rateexceptionforlilly}`
            : ``
        } ${
          rateExceptionData.rateexceptionforbi
            ? `BI $${rateExceptionData.rateexceptionforbi}`
            : ``
        }</p>
      <p>How was assigned FMV rate rejected by the external party?: ${
        rateExceptionData.howfmvraterejected
      }</p><p>Description of the business need/contracted activity requiring this specific HCP: ${
          rateExceptionData.describecontractactivityhcp
        }</p><p>Is the activity intended to include this HCP on the Thought Leader Engagement Plan?: ${
          rateExceptionData.ishcponthoughtleaderplan
        } ${
          rateExceptionData.ishcponthoughtleaderplancomment
            ? rateExceptionData.ishcponthoughtleaderplancomment
            : ``
        }</p>
      <p>Identify the skills/knowledge/expertise this HCP possesses which may not be captured in the PPQ and support the identified business need/contracted activity?: ${
        rateExceptionData.hcpexpertise
      }</p><p>Contract managed via 3rd party: ${
          rateExceptionData.isthirdpartycontract
        }</p><p>${
          rateExceptionData.fmvadminteamcomment
            ? `Additional Comments: ${rateExceptionData.fmvadminteamcomment}`
            : ``
        }</p><p>Please click here for the <a href=https://collab.lilly.com/sites/GTToolkit/SiteAssets/SitePages/FMV/US-FMV-Rates.pdf>FMV</a> rate tables.</p><p>Can you please revert directly to this email with your approval/rejection decision. A copy of all correspondence will be attached to the initial exception request.</p><p> Kind regards,</p><p> Lilly FMV</p>`,
      };
      await FMVRateExceptionService.sendEmail(emailObj);
    }
    window.location.reload();
  };
  handleAdminClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ viewAdminDecision: !this.state.viewAdminDecision });
  };
  handleExceptionApproved = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { rateExceptionData } = this.props;
    rateExceptionData.exceptiondecision =
      "Exception Approved By Committee Member";
    rateExceptionData.fmvadminteamactiondate = new Date();
    this.setState({ adminApprovedDecision: true });
    await FMVRateExceptionService.update(
      rateExceptionData,
      rateExceptionData.requestid
    );
    if (rateExceptionData) {
      const exceptionAudit: RateExceptionAuditDTO = {
        requestid: rateExceptionData.requestid,
        facultyid: rateExceptionData.facultyid,
        actiontakenby: "FMV Admin Team",
        actiontype: rateExceptionData.exceptiondecision,
        actiondate: new Date(),
        actiondetail:
          "Exception Approved By FMV Admin Team on behalf of Committee Member ",
        comment: rateExceptionData.fmvadminteamcomment,
      };
      this.setState({ exceptionAudit });

      await FMVRateExceptionService.createAudit(exceptionAudit);
    }
    const emailObj: EmailDTO = {
      includeAdminInCC: true,
      to: rateExceptionData.requesteremail,
      cc: "",
      subject: `FMV Exception Request for ${rateExceptionData.firstname} ${rateExceptionData.lastname}`,
      body: `<p>Hello,</p><p> An FMV exception request has been approved by the Lilly FMV admin team on behalf of the Committee Members.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvreadonlyform/${rateExceptionData.requestid}> here</a>.</p><p> Kind regards,</p><p> Lilly FMV</p>`,
    };
    await FMVRateExceptionService.sendEmail(emailObj);
    window.location.reload();
  };
  handleExceptionDenied = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { rateExceptionData } = this.props;
    rateExceptionData.exceptiondecision =
      "Exception Denied By Committee Member";
    await FMVRateExceptionService.update(
      rateExceptionData,
      rateExceptionData.requestid
    );
    this.setState({ adminDeniedDecision: true });
    if (rateExceptionData) {
      const exceptionAudit: RateExceptionAuditDTO = {
        requestid: rateExceptionData.requestid,
        facultyid: rateExceptionData.facultyid,
        actiontakenby: "FMV Admin Team",
        actiontype: rateExceptionData.exceptiondecision,
        actiondate: new Date(),
        actiondetail:
          "Exception Denied By FMV Admin Team on behalf of Committee Member ",
        comment: rateExceptionData.fmvadminteamcomment,
      };

      await FMVRateExceptionService.createAudit(exceptionAudit);
    }
    const emailObj: EmailDTO = {
      includeAdminInCC: true,
      to: rateExceptionData.requesteremail,
      cc: "",
      subject: `FMV Exception Request for ${rateExceptionData.firstname} ${rateExceptionData.lastname}`,
      body: `<p>Hello,</p><p>An FMV exception request has been denied by the Lilly FMV admin team on behalf of the Committee Members.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvreadonlyform/${rateExceptionData.requestid}> here</a>.</p><p> Kind regards,</p><p> Lilly FMV</p>`,
    };
    await FMVRateExceptionService.sendEmail(emailObj);
    window.location.reload();
  };
  handleQuorumDecision = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const { rateExceptionData } = this.props;
    rateExceptionData.exceptiondecision = "Exception Approved-Quorum Decision";
    await FMVRateExceptionService.update(
      rateExceptionData,
      rateExceptionData.requestid
    );
    this.setState({ adminquorumDecision: true });
    if (rateExceptionData) {
      const exceptionAudit: RateExceptionAuditDTO = {
        requestid: rateExceptionData.requestid,
        facultyid: rateExceptionData.facultyid,
        actiontakenby: "FMV Admin Team",
        actiontype: rateExceptionData.exceptiondecision,
        actiondate: new Date(),
        actiondetail:
          "Exception Approved-Quorum Decision By FMV Admin Team on behalf of Committee Member ",
        comment: rateExceptionData.fmvadminteamcomment,
      };

      await FMVRateExceptionService.createAudit(exceptionAudit);
    }
    const emailObj: EmailDTO = {
      includeAdminInCC: true,
      to: rateExceptionData.requesteremail,
      cc: "",
      subject: `FMV Exception Request for ${rateExceptionData.firstname} ${rateExceptionData.lastname}`,
      body: `<p>Hello,</p><p>An FMV exception request has been approved-quorum decision by the Lilly FMV admin team on behalf of the Committee Members.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvreadonlyform/${rateExceptionData.requestid}> here</a>.</p><p> Kind regards,</p><p> Lilly FMV</p>`,
    };
    await FMVRateExceptionService.sendEmail(emailObj);
    window.location.reload();
  };
  handleExceptionCancelled = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { rateExceptionData } = this.props;
    rateExceptionData.exceptiondecision =
      "Exception Cancelled By Committee Member";
    rateExceptionData.exceptionrequeststatus = "Cancelled";
    await FMVRateExceptionService.update(
      rateExceptionData,
      rateExceptionData.requestid
    );
    this.setState({ adminCancelledDecision: true });
    if (rateExceptionData) {
      const exceptionAudit: RateExceptionAuditDTO = {
        requestid: rateExceptionData.requestid,
        facultyid: rateExceptionData.facultyid,
        actiontakenby: "FMV Admin Team",
        actiontype: rateExceptionData.exceptiondecision,
        actiondate: new Date(),
        actiondetail: "Exception Cancelled By FMV Admin Team",
        comment: rateExceptionData.fmvadminteamcomment,
      };

      await FMVRateExceptionService.createAudit(exceptionAudit);
    }
    const emailObj: EmailDTO = {
      includeAdminInCC: true,
      to: rateExceptionData.requesteremail,
      cc: "",
      subject: `FMV Exception Request for ${rateExceptionData.firstname} ${rateExceptionData.lastname}`,
      body: `<p>Hello,</p><p>FMV exception request has been cancelled by the Lilly FMV admin team.</p><p> Please find the details of the exception request<a href=${window.location.origin}/fmvreadonlyform/${rateExceptionData.requestid}> here</a>.</p><p> Kind regards,</p><p> Lilly FMV</p>`,
    };
    await FMVRateExceptionService.sendEmail(emailObj);
    window.location.reload();
  };
}
export default FMVAdminDecision;