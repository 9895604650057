import React from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  TextareaAutosize,
  Divider,
  Paper,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";
import FileUpload from "../../Components/File/FileUpload";
import CheckBox from "../../Components/Checkbox/Checkbox";
import { Header, StyleGrid } from "./App.style";
import { ExceptionManagementDTO, FMVFileUploadDTO } from "../../Models";
import {
  CustomEventType,
  DocumentType,
  UserRole,
  FileType,
} from "../../Common/Enums";
import FileUploadModel from "../../Components/File/FileUploadModel";
import RadioButton from "../../Components/RadioButton/RadioButton";
import CheckboxModel from "../../Components/Checkbox/CheckboxModel";
import FacultyDetailCard from "../../Components/Card/FacultyDetailCard";
import {
  CommitteeMemberManagementService,
  CustomEventHandlerService,
  FMVRateExceptionService,
  AuthProviderService,
} from "../../Services";
import DocumentsAttached from "./DocumentsAttached";
import UploadedDoc from "../FMVForm/DocumentsAttached";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import FMVAdminDecision from "./FMVAdminDecision";
import VPAndCommitteDecision from "./VPAndCommitteeDecision";
import ExceptionAuditTrail from "./ExceptionAuditTrail";
import {
  LillyExceptionSoughtData,
  BIExceptionSoughtData,
  LeaderEngagementPlanData,
  AssociatedWithActivityData,
  ThirdPartyContractData,
} from "../../Data/FMVRateExceptionData";
import Utils from "../../Common/Utils";

interface LabelProps {
  text: string;
}
const Label = (props: LabelProps) => {
  return (
    <Typography
      style={{
        fontWeight: 520,
        color: "#000000d6",
        marginRight: 30,
      }}
    >
      {props.text}
    </Typography>
  );
};

const textAreaStyle: React.CSSProperties = {
  width: "96%",
  fontSize: "16px",
  fontFamily: "Helvetica",
  resize: "none",
  background: "white",
  color: "#000000de",
  borderRadius: "5px",
  padding: "15px",
};

interface Props {
  rateExceptionData?: ExceptionManagementDTO;
}
interface State {
  rateExceptionData: ExceptionManagementDTO;
  fileUploadData: FMVFileUploadDTO;
  files: FileUploadModel[];
  isLoading: boolean;
  userEmail: string;
  userRole: string;
  isDoc: boolean;
  focused:boolean;
}
class RateExceptionForm extends React.Component<Props, State> {
  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.state = {
      rateExceptionData: {} as ExceptionManagementDTO,
      fileUploadData: {} as FMVFileUploadDTO,
      isLoading: true,
      userEmail: "",
      userRole: "",
      isDoc: false,
      files: [],
      focused:false
    };
  }

  async componentDidMount() {
    const userRole = localStorage.getItem("UserRole");
    if (userRole) {
      this.setState({ userRole });
    }
    const path: any = window.location.pathname;
    const requestid = path.split("/")[2];
    const requestData = await FMVRateExceptionService.getByRequestId(requestid);
    if (requestid) {
      this.loadDocData();
    }
    const { accessToken } = await AuthProviderService.getAccessToken();
    const loggedinUser = Utils.decodeJWT<any>(accessToken);
    this.setState({ userEmail: loggedinUser.preferred_username });

    if (requestData) {
      let allowToShow = false;
      const userRole = Utils.getUserRole();

      if (userRole) {
        if (userRole === UserRole.LILLY_ADMIN) {
          allowToShow = true;
        } else if (userRole === UserRole.LILLY_USER) {
          const { accessToken } = await AuthProviderService.getAccessToken();
          const loggedinUser = Utils.decodeJWT<any>(accessToken);

          const committeeMembers =
            await CommitteeMemberManagementService.getAll();
          const { requesteremail, vpapproveremail } = requestData;
          const showToEmails = [];

          if (requesteremail) {
            showToEmails.push(requesteremail);
          }

          if (vpapproveremail) {
            showToEmails.push(vpapproveremail.toLowerCase());
          }

          committeeMembers.map((member) => {
            showToEmails.push(member.email);

            return false;
          });

          const matchEmails = showToEmails.filter(
            (p) => p === loggedinUser.preferred_username
          );

          if (matchEmails.length > 0) {
            allowToShow = true;
          }
        }
      }

      if (allowToShow) {
        this.setState({
          rateExceptionData: requestData,
          isLoading: false,
        });
      } else {
        window.location.replace("/fmvform");
      }
    }
  }

  render(): React.ReactNode {
    const { rateExceptionData, isLoading, userEmail, userRole, isDoc, files,focused } =
      this.state;
    const checkboxStyle: React.CSSProperties = { color: "#EE3A29" };
    const path: any = window.location.pathname;
    const requestid = path.split("/")[2];

    if (isLoading) {
      return null;
    }

    return (
      <Box>
        {userEmail === rateExceptionData.requesteremail ? (
          <Grid container>
            <Link to={`/myrequest/${userEmail}`}>
              <FontAwesomeIcon
                style={{ color: "#ee3a29", marginBottom: 10, marginRight: 10 }}
                icon={faArrowLeft}
                size="lg"
              />
            </Link>
            <Typography
              style={{
                color: "#313131",
                marginBottom: 10,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Back
            </Typography>
          </Grid>
        ) : (
          <Grid container>
            <Link to="/rateupdateexceptions">
              <FontAwesomeIcon
                style={{ color: "#ee3a29", marginBottom: 10, marginRight: 10 }}
                icon={faArrowLeft}
                size="lg"
              />
            </Link>
            <Typography
              style={{
                color: "#313131",
                marginBottom: 10,
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Back
            </Typography>
          </Grid>
        )}
        <Header variant="h5">
          US External Party Fair Market Value (FMV) Increase Request Form
        </Header>

        <Card style={{ margin: 20, height: "100%" }}>
          <CardContent style={{ marginLeft: 10 }}>
            <StyleGrid container>
              <Grid container>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={5}>
                    <Label text="Requestor Name :" />
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="requestername"
                      value={rateExceptionData.requestername}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={5}>
                    <Label text="Function requesting exception:" />
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="functionrequestingexception"
                      value={rateExceptionData.functionrequestingexception}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={5}>
                    <Label text="Business Unit requesting exception:" />
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="businessunitrequestingexception"
                      value={rateExceptionData.businessunitrequestingexception}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={5}>
                    <Label text="Is there a marketed brand(s)/product(s)  associated with the activity the exception is being sought for if applicable? " />
                  </Grid>

                  <Grid item container xs={7}>
                    <Grid item xs={12}>
                      <RadioButton
                        values={AssociatedWithActivityData}
                        selectedValue={
                          rateExceptionData.associatedwithactivity.length > 2
                            ? "yes"
                            : "no"
                        }
                        onChange={this.handleCheckSelectChange}
                      />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      {rateExceptionData.associatedwithactivity.length > 2 ? (
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="associatedwithactivity"
                          value={rateExceptionData.associatedwithactivity}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={5}>
                    <Label text="Select approver for this request" />
                    <Typography
                      style={{
                        fontStyle: "italic",
                        color: "#6d6e6e",
                        marginRight: 30,
                      }}
                    >
                      (Senior VP or above in business unit/therapeutic
                      area/budget holder):
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="vpapprovername"
                      value={rateExceptionData.vpapprovername}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={5}>
                    <Label text="HCP Name" />
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="facultyid"
                      value={`${rateExceptionData.firstname} ${rateExceptionData.lastname}`}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={5}>
                    <Label text="Rate Exception being sought for :" />
                  </Grid>
                  <Grid item xs={7}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="exceptionsoughtforlilly"
                          checked={
                            rateExceptionData.rateexceptionforlilly
                              ? true
                              : false
                          }
                          style={checkboxStyle}
                        />
                      }
                      label={LillyExceptionSoughtData.map((p) => p.text)}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="exceptionsoughtforbi"
                          checked={
                            rateExceptionData.rateexceptionforbi ? true : false
                          }
                          style={checkboxStyle}
                        />
                      }
                      label={BIExceptionSoughtData.map((p) => p.text)}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={5}>
                    <Label text="Requested FMV Rate:" />
                  </Grid>
                  <Grid item container xs={7}>
                    <Grid item xs={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="exceptionsoughtforlilly"
                            checked={
                              rateExceptionData.rateexceptionforlilly
                                ? true
                                : false
                            }
                            style={checkboxStyle}
                          />
                        }
                        label={LillyExceptionSoughtData.map((p) => p.text)}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ marginRight: 20 }}>
                      {rateExceptionData.rateexceptionforlilly ? (
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="rateexceptionforlilly"
                          value={rateExceptionData.rateexceptionforlilly}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="exceptionsoughtforbi"
                            checked={
                              rateExceptionData.rateexceptionforbi
                                ? true
                                : false
                            }
                            style={checkboxStyle}
                          />
                        }
                        label={BIExceptionSoughtData.map((p) => p.text)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {rateExceptionData.rateexceptionforbi ? (
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="rateexceptionforbi"
                          value={rateExceptionData.rateexceptionforbi}
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={12}>
                    <FacultyDetailCard data={rateExceptionData} />
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={5}>
                    <Label text="How was assigned FMV rate rejected by the external party?" />
                  </Grid>
                  <Grid item xs={7}>
                    <TextareaAutosize
                      disabled
                      id="facultyid"
                      style={textAreaStyle}
                      rowsMin={1}
                      value={
                        rateExceptionData.howfmvraterejected === "Other"
                          ? rateExceptionData.howfmvraterejectedother
                          : rateExceptionData.howfmvraterejected
                      }
                    />
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={12}>
                    <Label text="Provide supporting rationale for this exception request:" />
                    <Divider
                      orientation="horizontal"
                      style={{ marginTop: 10, marginBottom: 10 }}
                    />
                  </Grid>
                  <Grid style={{ marginBottom: 30 }} container>
                    <Grid item xs={5}>
                      <Label text="Describe the business need/contracted activity requiring this specific HCP? " />
                    </Grid>
                    <Grid item xs={7}>
                      <TextareaAutosize
                        disabled
                        id="describecontractactivityhcp"
                        style={textAreaStyle}
                        rowsMin={1}
                        value={rateExceptionData.describecontractactivityhcp}
                      />
                    </Grid>
                  </Grid>
                  <Grid style={{ marginBottom: 30 }} container>
                    <Grid item xs={5}>
                      <Label text="Is the activity intended to include this HCP on the Thought Leader Engagement Plan?" />
                      <Typography
                        style={{
                          fontStyle: "italic",
                          color: "#6d6e6e",
                          marginRight: 30,
                        }}
                      >
                        (If No, enter comments.)
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <CheckBox
                        id="ishcponthoughtleaderplan"
                        selectedValue={
                          rateExceptionData.ishcponthoughtleaderplan
                        }
                        values={LeaderEngagementPlanData}
                        onChange={this.handleCheckSelectChange}
                      />
                      {rateExceptionData.ishcponthoughtleaderplancomment ? (
                        <TextareaAutosize
                          disabled
                          id="ishcponthoughtleaderplancomment"
                          style={textAreaStyle}
                          rowsMin={1}
                          value={
                            rateExceptionData.ishcponthoughtleaderplancomment
                          }
                        />
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                  <Grid style={{ marginBottom: 30 }} container>
                    <Grid item xs={5}>
                      <Label text="Identify the skills/knowledge/expertise this HCP possesses which may not be captured in the PPQ and support the identified business need? " />
                    </Grid>
                    <Grid item xs={7}>
                      <TextareaAutosize
                        disabled
                        id="hcpexpertise"
                        style={textAreaStyle}
                        rowsMin={1}
                        value={rateExceptionData.hcpexpertise}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{ marginBottom: 30 }} container>
                  <Grid item xs={5}>
                    <Label text="Is this contract managed via 3rd party?" />
                  </Grid>
                  <Grid item xs={7}>
                    <CheckBox
                      id="isthirdpartycontract"
                      selectedValue={rateExceptionData.isthirdpartycontract}
                      values={ThirdPartyContractData}
                      onChange={this.handleCheckSelectChange}
                    />
                  </Grid>
                </Grid>
                {rateExceptionData.additionalrequestedinformation ||
                rateExceptionData.vpadditionalrequestedinformation ||
                rateExceptionData.committeeadditionalrequestedinformation ? (
                  <Grid style={{ marginBottom: 30 }} container>
                    <Grid item xs={5}>
                      <Label text="Additional requested information : " />
                    </Grid>
                    <Grid item xs={7}>
                      {rateExceptionData.additionalrequestedinformation ? (
                        <Grid item container style={{ marginBottom: 15 }}>
                          <Grid item xs={3} style={{ marginBottom: 10 }}>
                            <Typography
                              style={{
                                color: "#000000d6",
                                fontWeight: 450,
                              }}
                            >
                              FMV Admin Comment:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography
                              style={{
                                color: "#6d6e6e",
                              }}
                            >
                              {rateExceptionData.fmvadminteamneedmoreinfo}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              style={{
                                color: "#000000d6",
                                fontWeight: 450,
                              }}
                            >
                              Requester Response:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <TextareaAutosize
                              disabled
                              id="additionalrequestedinformation"
                              style={textAreaStyle}
                              rowsMin={1}
                              value={
                                rateExceptionData.additionalrequestedinformation
                              }
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                      {rateExceptionData.vpapproverstatus ===
                        "VP Requested More Information" &&
                      rateExceptionData.vpadditionalrequestedinformation ? (
                        <Grid item container style={{ marginBottom: 15 }}>
                          <Grid item xs={3} style={{ marginBottom: 10 }}>
                            <Typography
                              style={{
                                color: "#000000d6",
                                fontWeight: 450,
                              }}
                            >
                              VP Approver Comment:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <Typography
                              style={{
                                color: "#6d6e6e",
                              }}
                            >
                              {rateExceptionData.vpapprovercomment}
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography
                              style={{
                                color: "#000000d6",
                                fontWeight: 450,
                              }}
                            >
                              Requester Response:
                            </Typography>
                          </Grid>
                          <Grid item xs={9}>
                            <TextareaAutosize
                              disabled
                              id="vpadditionalrequestedinformation"
                              value={
                                rateExceptionData.vpadditionalrequestedinformation
                              }
                              style={textAreaStyle}
                              rowsMin={1}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </StyleGrid>

            <Box style={{ marginBottom: 50 }}>
              <Grid container direction="row" justify="space-between">
                <Label text="Documents Attached :" />
              </Grid>
              <Divider
                orientation="horizontal"
                style={{ marginTop: 10, marginBottom: 20 }}
              />
              <DocumentsAttached request_id={requestid} />
            </Box>
            {userRole === "LILLY_ADMIN" ? (
              <FMVAdminDecision
                rateExceptionData={rateExceptionData}
                onChange={this.handleChange}
                onTextAreaInputChange={this.handleTextAreaInputChange}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
                focused={focused}
              />
            ) : (
              <></>
            )}

            <VPAndCommitteDecision
              rateExceptionData={rateExceptionData}
              onChange={this.handleChange}
              onTextAreaInputChange={this.handleTextAreaInputChange}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
              focused={focused}
            />
            <Grid>
              <Grid item xs={12}>
                <Label text="Attach any supporting documentation here." />
              </Grid>
              <Divider
                orientation="horizontal"
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <Grid item xs={12} style={{ marginBottom: 15 }}>
                <Paper>
                  <FileUpload
                    isDocumentUpload={true}
                    onChange={this.handleFileUpload}
                  />
                  {rateExceptionData?.requestid || isDoc ? (
                    <UploadedDoc
                      files={files}
                      onDownload={this.handleFileDownload}
                      onDelete={this.handleFileDelete}
                    />
                  ) : (
                    <></>
                  )}
                </Paper>
              </Grid>
            </Grid>
            {userRole === "LILLY_ADMIN" ||
            userEmail === rateExceptionData.requesteremail ? (
              <>
                {rateExceptionData.fmvadminteamstatus ||
                rateExceptionData.fmvexceptioncommittestatus ||
                rateExceptionData.vpapproverstatus ||
                rateExceptionData.exceptiondecision ||
                rateExceptionData ? (
                  <ExceptionAuditTrail
                    requestid={rateExceptionData.requestid}
                  />
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </CardContent>
        </Card>
      </Box>
    );
  }
  handleFocus = () => {
    this.setState({ focused: true });
  };
  handleBlur = () => {
    this.setState({ focused: false });
  };
  DetectOS = (window: any) => {
    let operatingSystem = "Not known";
    if (window.navigator.appVersion.indexOf("Win") !== -1) {
      operatingSystem = "Windows OS";
    }
    if (window.navigator.appVersion.indexOf("Mac") !== -1) {
      operatingSystem = "MacOS";
    }
    if (window.navigator.appVersion.indexOf("X11") !== -1) {
      operatingSystem = "UNIX OS";
    }
    if (window.navigator.appVersion.indexOf("Linux") !== -1) {
      operatingSystem = "Linux OS";
    }

    return operatingSystem;
  };

  OS = (window: any) => {
    return this.DetectOS(window);
  };

  DetectDevice = () => {
    console.log(`Device is : ${isMobile ? "Mobile" : "Desktop"}`);
  };
  handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
  ) => {
    if (event) {
      this.setState((prevState) => {
        let rateExceptionData: any = Object.assign(
          {},
          prevState.rateExceptionData
        );
        rateExceptionData[event.target.id] = event.target.value;
        return { rateExceptionData };
      });
    }
  };
  handleTextAreaInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement >
  ) => {
    if (event) {
      this.setState((prevState) => {
        let rateExceptionData: any = Object.assign(
          {},
          prevState.rateExceptionData
        );
        rateExceptionData[event.target.id] = event.target.value;
        return { rateExceptionData };
      });
    }
  };

  handleCheckSelectChange = (selected: CheckboxModel, targetId?: string) => {
    // console.log(targetId);
  };

  handleFileUpload = async (file: FileUploadModel) => {
    // console.log(file);
    const path: any = window.location.pathname;
    const requestid = path.split("/")[2];
    let { rateExceptionData, fileUploadData } = this.state;
    let fileModel = new FileUploadModel();
    fileModel = file;
    file.facultyId = rateExceptionData.facultyid;
    fileUploadData.customerid = rateExceptionData.customerid;
    fileUploadData.facultyid = rateExceptionData.facultyid;
    fileUploadData.filename = file.name;
    fileUploadData.id = file.id;
    fileUploadData.requestid = requestid;
    fileUploadData.filetype = file.type;
    //rateException.documentsattached = file;

    this.setState({ fileUploadData });
    const response = await FMVRateExceptionService.uploadFile(
      file,
      DocumentType.APPROVER,
      requestid,
      rateExceptionData.customerid,
      rateExceptionData.facultyid
    );
    if (response) {
      this.loadDocData();
    }
    // console.log("key", fileModel.sizeInKB);
    if (fileModel.sizeInKB > 10000) {
      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, {
        alertId: "fileUpload",
        message: "Error in file upload",
      });
      // console.log("size exceeded");
    } else {
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "fileUpload",
        message: "File uploaded successfully.",
      });
    }

    return { rateExceptionData };
  };
  loadDocData = async () => {
    const path: any = window.location.pathname;
    const requestid = path.split("/")[2];

    //console.log("requestid", requestid);
    if (requestid) {
      const exceptionFiles = await FMVRateExceptionService.getAllFile(
        requestid,
        DocumentType.APPROVER
      );
      //console.log("exceptionfiles",exceptionFiles)
      const files: FileUploadModel[] = [];

      exceptionFiles
        .filter((file) => file.documenttype === DocumentType.APPROVER)
        .forEach((exceptionFile) => {
          const file = new FileUploadModel();

          file.id = exceptionFile.id;
          file.name = exceptionFile.filename;
          file.facultyId = exceptionFile.facultyid;
          file.type =
            FileType[exceptionFile.documenttype as keyof typeof FileType];
          file.s3filekey = exceptionFile.s3filekey;
          file.sizeInKB = exceptionFile.fileSizeInKB ?? 0;

          files.push(file);
        });

      this.setState({ files, isDoc: true });
    }
  };

  handleFileDownload = async (file: FileUploadModel) => {
    const downloadURL = await FMVRateExceptionService.getFile(
      file.s3filekey,
      file.type
    );
    Utils.openUrlParent(downloadURL);
  };

  handleFileDelete = async (file: FileUploadModel) => {
    const isSuccess = await FMVRateExceptionService.deleteFile(
      file,
      DocumentType.APPROVER
    );
    let message = "";

    if (isSuccess) {
      const { files } = this.state;

      const index = files.findIndex(
        (p) => p.name === file.name && p.type === file.type
      );
      files.splice(index, 1);

      this.setState({ files }, () => {
        const fileInput = document.getElementById("otherUpload") as HTMLInputElement;

        if (fileInput) {
            fileInput.value = '';
        }
      });

      message = "File Deleted successfully";
      CustomEventHandlerService.dispatch(CustomEventType.SuccessAlert, {
        alertId: "fileUpload",
        message,
      });
    } else {
      message = "Failed to delete File";
      CustomEventHandlerService.dispatch(CustomEventType.ErrorAlert, {
        alertId: "fileUpload",
        message,
      });
    }
  };
}

export default RateExceptionForm;